$(document).ready(function(){

  $("#successful_participation").click(function() {
    if(this.checked) {
      var box = confirm("Wenn Sie die Teilnahme erfolgreich abschließen und anschließend speichern kann dieser Datensatz nicht mehr bearbeitet werden.");
    }
    if (box==false)
      return false
    else
      return true
  });

  $("#cancelled_participation").click(function() {
    if(this.checked) {
    var box = confirm("Wenn Sie die Teilnahme abbrechen abschließen und anschließend speichern kann dieser Datensatz nicht mehr bearbeitet werden.");
    if (box==false)
      return false
    else
      return true
    }
  });

  $("#deactivate_institution").click(function() {
    if(this.checked) {
    var box = confirm("Wenn Sie die Institution deaktivieren und anschließend speichern kann dieser Datensatz nicht mehr bearbeitet werden.");
    if (box==false)
      return false
    else
      return true
    }
  });

  $("#deactivate_counselor").click(function() {
    if(this.checked) {
    var box = confirm("Wenn Sie die Ausbildungsbegleitung deaktivieren und anschließend speichern kann dieser Datensatz nicht mehr bearbeitet werden.");
    if (box==false)
      return false
    else
      return true
    }
  });


});