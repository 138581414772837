// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "@hotwired/turbo-rails"
Turbo.session.drive = false
import "./controllers"
import "bootstrap"
import "bootstrap4-toggle/js/bootstrap4-toggle.min.js"
import "../stylesheets/application.scss"
import Rails from "@rails/ujs";
Rails.start();
import "chartkick/chart.js"
require("jquery")
require('jquery-ui/ui/widgets/autocomplete')
require("@fortawesome/fontawesome-free/js/all")
import "./packs/inactive_confirmation.js"
import "./counselor_interface/apprentice.js";
import "./counselor_interface/company.js";
const images = require.context('../images', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
